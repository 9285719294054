<template>
  <div>
    <el-card>
      <el-row>
        <el-button
          style="float: right"
          type="primary"
          @click="addFromDialogVisabled = true"
        >
          更新版本
        </el-button>
      </el-row>
      <!--
    <h1>智美OA</h1>
    <div style="padding: 10px">最新版本号:{{ version.versionNewCode }}</div>
    <div style="padding: 10px">更新内容:{{ version.versionInfo }}</div>
    <div style="padding: 10px">
      文件下载地址:<el-link :href="version.filePath" target="_blank">{{
        version.filePath
      }}</el-link>
    </div>
    -->
      <el-dialog
        :close-on-click-modal="false"
        :close-on-press-escape="false"
        :show-close="false"
        :visible.sync="addFromDialogVisabled"
        @close="closeAddFromDialogHandle"
      >
        <el-form :v-model="addFrom" ref="addFromRef">
          <el-form-item prop="newVersion" label="新版本号">
            <el-input
              v-model="addFrom.newVersion"
              placeholder="请输入新版本号"
            ></el-input>
          </el-form-item>
          <el-form-item prop="file" label="上传文件:">
            <el-upload
              ref="upload"
              action="#"
              :http-request="httpRequest"
              :before-upload="beforeUpload"
              :on-exceed="handleExceed"
              :limit="1"
            >
              <el-button slot="trigger" size="small" type="primary"
                >选取文件</el-button
              >
              <div slot="tip" class="el-upload__tip">
                只能上传.apk文件，且不超过200M
              </div>
            </el-upload>
          </el-form-item>
          <el-form-item prop="message" label="更新内容">
            <el-input
              type="textarea"
              v-model="addFrom.message"
              :rows="5"
            ></el-input>
          </el-form-item>
          <el-progress
            v-show="isStartUpload"
            :percentage="uploadValue"
          ></el-progress>
        </el-form>
        <span slot="footer" class="dialog-footer">
          <el-button
            :disabled="isStartUpload"
            @click="addFromDialogVisabled = false"
            >取 消</el-button
          >
          <el-button
            :disabled="isStartUpload"
            type="primary"
            @click="uploadFrom"
            >确 定</el-button
          >
        </span>
      </el-dialog>
    </el-card>
    <div
      class="oa_box"
      style="margin-top: 20px"
      :style="{ backgroundImage: `url(${bgUrl})` }"
    >
      <div class="qr_title">智美OA系统</div>
      <img :src="bgUrl" style="width: 100%" />
      <img class="icon_box" :src="zmoaimage" />
      <div class="qr_box">
        <vue-qr
          :text="version.filePath"
          :margin="0"
          :logoSrc="snowyiLogo"
          :logoScale="0.25"
          colorDark="#000"
          colorLight="#fff"
          :size="287"
        />
        <div class="qr_title_1">扫码下载</div>
        <div class="qr_title_2">最新版本号:{{ version.versionNewCode }}</div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import { getOAVersion } from '@/api/softVersion'
import vueQr from 'vue-qr'
import zmoabg from '@/assets/app/zmoabg.png'
import zmoaimage from '@/assets/app/zmoaimage.png'
import snowyiLogo from '@/assets/app/logo.png'
export default {
  components: {
    vueQr,
  },
  data() {
    return {
      bgUrl: zmoabg + '?' + new Date(),
      zmoaimage: zmoaimage + '?' + new Date(),
      snowyiLogo: snowyiLogo + '?' + new Date(),
      addFromDialogVisabled: false,
      version: {
        versionNewCode: '',
        versionInfo: '',
        filePath: null,
      },
      addFrom: {
        newVersion: '',
        message: '',
        file: [],
      },
      isStartUpload: false,
      uploadValue: 0,
    }
  },
  created() {},
  mounted() {
    this.getVersionInfo()
  },
  methods: {
    async getVersionInfo() {
      let { data } = await getOAVersion()
      this.version = data
    },
    // 覆盖默认的上传行为，可以自定义上传的实现，将上传的文件依次添加到fileList数组中,支持多个文件
    httpRequest(option) {
      this.addFrom.file.push(option)
    },
    // 上传前处理
    beforeUpload(file) {
      let fileSize = file.size
      const FIVE_M = 200 * 1024 * 1024
      //大于5M，不允许上传
      if (fileSize > FIVE_M) {
        this.$message.error('最大上传200M')
        return false
      }
      //判断文件类型，必须是xlsx格式
      let fileName = file.name
      let reg = /^.+(\.apk)$/
      if (!reg.test(fileName)) {
        this.$message.error('只能上传apk文件!')
        return false
      }
      return true
    },
    // 文件数量过多时提醒
    handleExceed() {
      this.$message({ type: 'error', message: '最多支持1个文件上传' })
    },
    closeAddFromDialogHandle() {
      this.addFrom = {
        newVersion: '',
        message: '',
        file: [],
      }
      this.$refs.upload.clearFiles()
    },
    uploadFrom() {
      if (
        !this.addFrom.newVersion ||
        !this.addFrom.message ||
        this.addFrom.file.length == 0
      ) {
        return this.$message.error('当前的软件信息填写不完整')
      }
      const formData = new FormData()
      formData.append('newVersion', this.addFrom.newVersion)
      formData.append('message', this.addFrom.message)
      formData.append('file', this.addFrom.file[0].file)

      this.isStartUpload = true

      let that = this

      axios
        .post('/api/SoftVersion/AddNewOA', formData, {
          baseURL: process.env.VUE_APP_API_URL,
          timeout: 300000,
          headers: { 'Content-Type': 'multipart/form-data' },
          onUploadProgress(e) {
            that.uploadValue = parseInt((e.loaded / e.total) * 100)
          },
        })
        .then((res) => {
          if (res && res.data && res.data.code == 200) {
            this.$message.success('文件上传成功')
          } else {
            this.$message.error('文件上传失败')
          }

          this.getVersionInfo()

          this.uploadValue = 0
          this.isStartUpload = false
          this.addFromDialogVisabled = false
        })
        .catch((err) => {
          this.uploadValue = 0
          this.isStartUpload = false
          this.$message.error(err)
        })
    },
  },
}
</script>

<style lang="scss" scoped>
.oa_box {
  position: relative;
  min-width: 1600px;
  .qr_title {
    position: absolute;
    top: 160px;
    left: 252px;
    font-size: 97px;
    font-weight: 400;
    color: #ffffff;
    text-shadow: 7px 12px 19px #2a2384;
  }

  .qr_box {
    position: absolute;
    left: 332px;
    top: 316px;
    .qr_title_1 {
      width: 100%;
      text-align: center;
      font-size: 30px;
      font-weight: 400;
      color: #ffffff;
    }
    .qr_title_2 {
      width: 100%;
      text-align: center;
      font-size: 26px;
      opacity: 0.63;
      font-weight: 400;
      color: #ffffff;
    }
  }
  .icon_box {
    position: absolute;
    width: 690px;
    height: 760px;
    top: 20px;
    right: 33px;
  }
}
</style>
